import { useEffect, useState } from 'react'

import axios from 'axios'

import { Box } from '@mui/material'
import { Logo } from '../../components';
import { ErrorPage } from "../ErrorPage"
import { LoadingPage } from '../LoadingPage'
import { Footer } from "../../components/Footer"
import { Wallpaper } from '../../components/Wallpaper'
import { DataDisplayTitle, DataDisplayDescription } from '../PersonalDocument/styles';
import { Button } from '../../components';
import { Checkbox } from "@mui/material";

export const ResumeInsurance = (props) => {

    const { clientThemeSettings, signData, setSignerStep, setSignData } = props
    const initialError = {"has_error": false, "status": 0}

    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [hasMadeRequest, setHasMadeRequest] = useState(false)
    const [isChecked, setIsChecked] = useState(false)

    const handleCheckboxChange = (event) => {
      setIsChecked(event.target.checked)
    };

    const coveredRisks = {
        accidental_death: {
            "title": "Morte por acidente",
            "description": "A morte do segurado, sempre que esta esteja relacionada a causas acidentais.",
        },
        permanent_disability: {
            "title": "Invalidez acidental",
            "description": "A invalidez permanente total por acidente do segurado, decorrente de lesões físicas ocorridas por causas acidentais.",
        },
        funeral_assistance: {
            "title": "Auxílio Funeral",
            "description": "Auxílio para o funeral do segurado, independente da causa mortis, na forma de reembolso de custos.",
        },
        medical_assistance: {
            "title": "Assistência em Medicamentos",
            "description": "Pronto atendimento para indicar farmácias cadastradas e até 80% de desconto em medicamentos.",
        }
      }

    useEffect(() => {
        setLoading(false)
    }, [])

    if (loading) {
        return (
            <LoadingPage clientThemeSettings={clientThemeSettings} />
        )
    }

    const formatCurrency = (value) => {
        const valueInReais = value / 100
    
        return new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(valueInReais)
      };

    const handleClick = () => {

        if (hasMadeRequest) {
            return  
        }

        const payload = {
            "step": "resume_insurance",
            "event_date": new Date().toISOString()
        }
    
        const config = {
            headers:
            {
                'Authorization': signData.signer_token
            }
        }
    
        setLoading(true)
        setHasMadeRequest(true)

        axios.patch('/signer', payload, config)
        .then(response => {
            setSignerStep(response.data.next_step)
            setSignData({ ...signData, ...response.data })
        })
        .catch((error) => {
            if ([401, 403].includes((error.response || {}).status)) {
                setError({
                    "has_error": true,
                    "status": error.response.status
                })
            } else {
                setError({
                    "has_error": true,
                    "status": 500
                })
            }

            setHasMadeRequest(false)
        })
        .finally(() => {
            setLoading(false)
        })
    }

    return (
         <Wallpaper backgroundColor={clientThemeSettings["background_color"]} style={{ padding: '10px' }}>
                    <Box className="base-container base-container-width base-container-padding" style={{ backgroundColor: "rgb(245, 245, 242)", marginBottom: "190px" }}>
                        <Logo imageSource={clientThemeSettings.logo_url} />
                        <Box style={{width: '95%'}}>
                            {(
                                <Box>
                                    <DataDisplayDescription style={{textAlign: "center"}}><b>Informações do Seguro</b></DataDisplayDescription>
                                    <Box style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', width: '100%' }}>
                                        <Box className="box-display-container">
                                            <DataDisplayTitle className="title-level2" style={{textAlign:"center"}}>Seguro de Acidentes Pessoais</DataDisplayTitle>
                                            <DataDisplayDescription style={{marginTop: "20px"}}>Razão Social da Seguradora:</DataDisplayDescription>
                                            <DataDisplayDescription><b>{signData.insurance_data.insurance_provider.legal_name}</b></DataDisplayDescription>
                                            <DataDisplayDescription style={{marginTop: "20px"}}>CNPJ da Seguradora:</DataDisplayDescription>
                                            <DataDisplayDescription><b>{signData.insurance_data.insurance_provider.document_number}</b></DataDisplayDescription>
                                            <DataDisplayDescription style={{marginTop: "20px"}}>Processo SUSEP:</DataDisplayDescription>
                                            <DataDisplayDescription><b>{signData.insurance_data.susep_process}</b></DataDisplayDescription>
                                        </Box>

                                        <Box className="box-display-container">
                                            <DataDisplayTitle className="title-level2" style={{textAlign:"center"}}>Riscos Cobertos</DataDisplayTitle>
                                            {signData.insurance_data.covered_risks.map((risk) => (
                                            <Box className="box-display-container" key={risk} style={{backgroundColor: clientThemeSettings.button_color}}>
                                                <DataDisplayTitle className="title-level2" style={{ textAlign: "center", color: clientThemeSettings.font_color }}>
                                                {coveredRisks[risk].title}
                                                </DataDisplayTitle>
                                                <DataDisplayDescription className="description-level2" style={{ textAlign: "center", color: clientThemeSettings.font_color }}>
                                                {coveredRisks[risk].description}
                                                </DataDisplayDescription>
                                            </Box>
                                            ))}
                                        </Box>

                                    <Box className="box-display-container">

                                        <DataDisplayTitle className="title-level2" style={{textAlign:"center"}}>Detalhes do Seguro</DataDisplayTitle>
        
                                        {/* <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                            <DataDisplayDescription style={{ flex: 1 }}>
                                            Assegurado:
                                            </DataDisplayDescription>
                                            <DataDisplayDescription style={{ textAlign: 'right' }}>
                                                <b>NAO TEMOS</b>
                                            </DataDisplayDescription>
                                        </Box>
         */}
                                        {/* <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                            <DataDisplayDescription style={{ flex: 1 }}>
                                                Data de Nascimento:
                                            </DataDisplayDescription>
                                            <DataDisplayDescription style={{ textAlign: 'right' }}>
                                                <b>NAO TEMOS</b>
                                            </DataDisplayDescription>
                                        </Box> */}
        
                                        <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                            <DataDisplayDescription style={{ flex: 1 }}>
                                            Vigência:
                                            </DataDisplayDescription>
                                            <DataDisplayDescription style={{ textAlign: 'right' }}>
                                                <b>{signData.insurance_data.coverage_period} meses</b>
                                            </DataDisplayDescription>
                                        </Box>
        
                                    </Box>
                                    <Box className="box-display-container">
        
                                        <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                            <DataDisplayDescription style={{ flex: 1 }}>
                                            Prêmio:
                                            </DataDisplayDescription>
                                            <DataDisplayDescription style={{ textAlign: 'right' }}>
                                            <b>{formatCurrency(signData.insurance_data.premium_amount)}</b>
                                            </DataDisplayDescription>
                                        </Box>
        
                                    </Box>

                                    <DataDisplayTitle className="description-level2" style={{textAlign:"center"}}>O seguro passa a ser válido a partir da data do pagamento do contrato</DataDisplayTitle>

                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Box>
                    {error.has_error &&
                        <ErrorPage
                            error={error}
                            redirectToLogin={() => setSignerStep("login")}
                            resetError={() => setError(initialError)}
                            clientThemeSettings={clientThemeSettings}
                        />
                    }
                        <Box
                                style={{
                                    position: "fixed",
                                    bottom: 0,
                                    width: "100%",
                                    alignItems: "center",
                                    textAlign: "center",
                                    backgroundColor: "#f9f9f9",
                                    padding: "10px 15px",
                                    borderTop: "1px solid #ccc"
                                }}
                            >
                                <Box style={{ display: "flex", alignItems: "left", justifyContent: "left", textAlign: "left", padding: "10px"}}>
                                        <DataDisplayDescription style={{ marginRight: "2px", marginLeft: "10px" }}>
                                        <b>Declaro que li e aceito que o valor do seguro será incluído no contrato.</b>
                                        </DataDisplayDescription>
                                        <Checkbox
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                        color="primary"
                                        style={{marginRight: "10px"}}
                                        />
                                </Box>
                                <Button
                                    onClick={handleClick}
                                    type="primary"
                                    disabled={!isChecked}
                                    clientThemeSettings={clientThemeSettings}
                                >
                                    Aceitar termos do contrato
                                </Button>


                                <Footer />
                        </Box>
                </Wallpaper>
    )
}