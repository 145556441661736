import { useEffect, useState } from 'react'

import axios from 'axios'

import RGIcon from '../../assets/images/rg-icon.png'
import CNHIcon from '../../assets/images/cnh-icon.png'
import CNHDigitalIcon from '../../assets/images/cnh-digital-icon.png'
import RNEIcon from '../../assets/images/rne-icon.png'
import PassportIcon from '../../assets/images/passport-icon.png'
import CTPSIcon from '../../assets/images/ctps-icon.png'
import OthersIcon from '../../assets/images/others-icon.png'

import { Box } from '@mui/material'
import { Logo } from '../../components';
import { ErrorPage } from "../ErrorPage"
import { LoadingPage } from '../LoadingPage'
import { Footer } from "../../components/Footer"
import { getColorContrast } from '../../utils/helpers'
import { Wallpaper } from '../../components/Wallpaper'
import { CollectImageTitle, DocumentIcon, DocumentText } from './styles'

export const AdditionalDocument = (props) => {

    const { clientThemeSettings, signData, setSignerStep, setSignData } = props
    const initialError = { "has_error": false, "status": 0 }

    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [hasMadeRequest, setHasMadeRequest] = useState(false)

    const documentTemplates = {
        'rg': {
            'icon': RGIcon,
            'title': 'Cédula de identidade (RG)'
        },
        'cnh': {
            'icon': CNHIcon,
            'title': 'Carteira de Habilitação (CNH)'
        },
        'cnh_digital': {
            'icon': CNHDigitalIcon,
            'title': 'Carteira de Habilitação Digital (CNH)'
        },
        'passport': {
            'icon': PassportIcon,
            'title': 'Passaporte'
        },
        'rne': {
            'icon': RNEIcon,
            'title': 'Registro Nacional de Estrangeiros (RNE)'
        },
        'crnm': {
            'icon': RNEIcon,
            'title': 'Carteira de Registro Nacional Migratório (CRNM)'
        },
        'ctps': {
            'icon': CTPSIcon,
            'title': 'Carteira de Trabalho e Previdência Social (CTPS)'
        },
        'others': {
            'icon': OthersIcon,
            'title': 'Outros Documentos'
        }
    }

    const defaultClientDocumentTemplates = [
        'rg',
        'cnh'
    ]

    const clientDocumentTemplates = (signData.document_templates !== undefined && signData.document_templates !== null)
        ? signData.document_templates
        : defaultClientDocumentTemplates;


    useEffect(() => {
        setLoading(false)
    }, [])

    if (loading) {
        return (
            <LoadingPage clientThemeSettings={clientThemeSettings} />
        )
    }

    var htmlComponent = document.getElementById('root')

    var webOCR = new window.QiTechWebOCR.WebOCR(
        htmlComponent,
        signData.ocr_token,
        signData.session_id
    )
        .setThemeConfiguration(
            {
                "companyLogo": clientThemeSettings["logo_url"],
                "backgroundColor": clientThemeSettings["background_color"],
                "buttonColor": clientThemeSettings["button_color"],
                "buttonFontColor": getColorContrast(clientThemeSettings["button_color"]),
                "modalBackgroundColor": "#F5F5F2",
                "fontColor": "#334150"
            }
        )
        .setShowInstructionScreen(true)
        .setShowSuccessScreen(false)

    process.env.REACT_APP_ENV === 'production' ? webOCR.build() : webOCR.setSandboxEnvironment().build()

    function initOCR(document_type) {
        webOCR.initialize(document_type)
            .then((ocr_key) => {
                submitOCRKeys(ocr_key)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const submitOCRKeys = (ocrKeys) => {

        if (hasMadeRequest) {
            return
        }

        const firstOcrKey = ocrKeys[0]
        const secondOcrKey = ocrKeys[1]

        const firstDocumentArray = firstOcrKey['template'].split('_')
        const ocrFrontKey = firstDocumentArray[firstDocumentArray.length - 1] === 'front' ? firstOcrKey['ocr_key'] : secondOcrKey['ocr_key']
        const ocrBackKey = firstDocumentArray[firstDocumentArray.length - 1] === 'back' ? firstOcrKey['ocr_key'] : secondOcrKey['ocr_key']

        const payload = {
            "ocr_front_key": ocrFrontKey,
            "ocr_back_key": ocrBackKey,
            "step": "additional_document",
            "event_date": new Date().toISOString()
        }

        const config = {
            headers:
            {
                'Authorization': signData.signer_token
            }
        }

        setLoading(true)
        setHasMadeRequest(true)

        axios.patch('/signer', payload, config)
            .then(response => {
                setSignerStep(response.data.next_step)
                setSignData({ ...signData, ...response.data })
            })
            .catch((error) => {
                if ([401, 403].includes((error.response || {}).status)) {
                    setError({
                        "has_error": true,
                        "status": error.response.status
                    })
                } else {
                    setError({
                        "has_error": true,
                        "status": 500
                    })
                }

                setHasMadeRequest(false)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <Wallpaper backgroundColor={clientThemeSettings["background_color"]} style={{ padding: '10px' }}>
            <div className="base-container base-container-width base-container-padding" style={{ backgroundColor: "rgb(245, 245, 242)" }}>
                <Logo imageSource={clientThemeSettings.logo_url} />
                <Box>
                    <div>
                        <CollectImageTitle>
                            Olá {signData.additional_information_data?.name || ""}, agora vamos coletar imagens do seu documento. Escolha entre os tipos abaixo:
                        </CollectImageTitle>

                        {clientDocumentTemplates && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '20px', marginBottom: '20px' }}>

                            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', width: '100%' }}>
                                {clientDocumentTemplates.map((type, index) => (
                                    <button
                                        key={index}
                                        onClick={() => initOCR(type)}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: '10px 15px',
                                            border: '1px solid #ccc',
                                            borderRadius: '5px',
                                            backgroundColor: '#f9f9f9',
                                            outline: 'none',
                                            WebkitTapHighlightColor: 'transparent'
                                        }}
                                        type='primary'
                                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#e9e9e9'}
                                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#f9f9f9'}
                                    >
                                        <DocumentIcon
                                            src={documentTemplates[type]['icon']}
                                            alt={type}
                                        />
                                        <DocumentText>{documentTemplates[type]['title']}</DocumentText>
                                    </button>
                                ))}
                            </div>

                        </div>}

                    </div>
                </Box>
                <Footer />
            </div>
            {error.has_error &&
                <ErrorPage
                    error={error}
                    redirectToLogin={() => setSignerStep("login")}
                    resetError={() => setError(initialError)}
                    clientThemeSettings={clientThemeSettings}
                />
            }
        </Wallpaper>
    )
}