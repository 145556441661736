import React, { useCallback, useState, useEffect } from "react"
import { DocumentsInbox } from "../DocumentsInbox"
import { FaceRecognition } from "../FaceRecognition"
import { Login } from "../Login"
import { PersonalDocument } from "../PersonalDocument"
import { AdditionalDocument } from "../AdditionalDocument"
import { DeviceScan} from "../DeviceScan"
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { NotFoundPage } from '../NotFoundPage'
import { ExpiredPage } from '../ExpiredPage'
import { LoadingPage } from '../LoadingPage'
import { CanceledPage } from '../CanceledPage'
import { defaultThemeSettings, cpfMask, formatToYYYYMMDD } from '../../utils/utils'
import { SignedPage } from "../SignedPage"
import { OTPPage } from "../OTPPage"
import ErrorBoundaryComponent from "../ErrorBoundary"
import { useCookies } from 'react-cookie'
import { v4 as uuidv4 } from 'uuid'
import { ResumeOperation } from "../ResumeOperation"
import { TermsOfUse } from "../TermsOfUse"
import { FraudWarning } from "../FraudWarning"
import { ResumeInsurance } from "../ResumeInsurance"
import { TermOfAuthorization } from "../TermOfAuthorization"

export const MainContainer = (props) => {

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [expired, setExpired] = useState(false)
    const [canceled, setCanceled] = useState(false)
    const [clientThemeSettings, setClientThemeSettings] = useState(undefined)
    const { signerHash } = useParams()
    const [signerStep, setSignerStep] = useState("")
    const [signData, setSignData] = useState({})
    const [latitude, setLatitude] = useState(undefined)
    const [longitude, setLongitude] = useState(undefined)
    const [cookies, setCookie] = useCookies([`qisign_${signerHash}`])
    const [alreadySigned, setAlreadySigned] = useState(false)

    const collectGeoLocation = useCallback(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLatitude(position.coords.latitude)
                    setLongitude(position.coords.longitude)
                }
            )
        }
    }, [])

    const onSendOTPCode = (signer_token) => {
        const data = {
            "event_date": new Date().toISOString()
        }
        const config = {
            headers:
            {
                'Authorization': signer_token
            }
        }
        axios.post('/signer/authentication', data, config)
            .then((response) => {
            })
            .catch((error) => {
            })
    }

    const submitLogin = useCallback((documentNumber, birthdate) => {
        const payload = {
            "document_number": cpfMask(documentNumber)
        }

        if (birthdate) {
            payload["birthdate"] = formatToYYYYMMDD(birthdate)
        }

        if (latitude !== undefined & longitude !== undefined) {
            payload["gps_data"] = {
                "coordinates": [
                    longitude,
                    latitude
                ]
            }
        }

        setLoading(true)
        axios.post('/signer/hash/' + signerHash, payload)
            .then(async (response) => {
                const session_id = uuidv4()
                setSignData({ ...signData, ...response.data, session_id })
                setCookie(`qisign_${signerHash}`, payload, { path: `/s/${signerHash}`, maxAge: 60 * 60 })
                setSignerStep(response.data.next_step)
            }).catch((error) => {
                setCookie(`qisign_${signerHash}`, cookies, { path: `/s/${signerHash}`, maxAge: 0 })
            })
            .finally(() => {
                setLoading(false)
            })
    }, [setCookie, setSignData, latitude, longitude, signerHash, signData, setSignerStep, cookies])

    useEffect(() => {
        collectGeoLocation()

        if (clientThemeSettings) return
        const defaultClientThemeSettings = {
            "logo_url": "https://qisign-app-production.s3.amazonaws.com/logos/qi_tech_azul_claro_escuro_principal.png",
            "font_color": "#000000",
            "button_color": "#4FCCED",
            "email_logo_url": "https://qisign-app-production.s3.amazonaws.com/logos/qi_tech_branco_email.png",
            "background_color": "#0E3573"
        }

        axios.get('/signer/hash/' + signerHash)
            .then((response) => {
                setSignData(response.data)
                setClientThemeSettings(response.data.client_theme_settings ? response.data.client_theme_settings : defaultClientThemeSettings)
                if (["signed", "sending_sign_receipt", "receipt_sent", "analyzing", "analyzed", "completed" ].includes(response.data.status)) {
                    if (response.data.status !== "signed") {
                        setAlreadySigned(true)
                    }
                    setSignerStep("signed")
                } else if (response.data.status === "canceled") {
                    setCanceled(true)
                } else if (response.data.status === "expired") {
                    setExpired(true)
                }
                else {
                    setSignerStep("login")
                }
            }).catch((error) => {
                setError(true)
            })
            .finally(() => {
                const documentNumberParamQueryParam = new URLSearchParams(window.location.search).get('document_number')
                const birthdateParamQueryParam = new URLSearchParams(window.location.search).get('birthdate')

                if (documentNumberParamQueryParam) {
                    submitLogin(documentNumberParamQueryParam, birthdateParamQueryParam)
                }
                setLoading(false)
            })
    }, [signerHash, clientThemeSettings, submitLogin, collectGeoLocation])

    const getScenes = () => {
        if (loading) {
            return (
                <LoadingPage clientThemeSettings={clientThemeSettings ? clientThemeSettings : defaultThemeSettings} />
            )
        }

        if (error) {
            return (
                <NotFoundPage clientThemeSettings={clientThemeSettings ? clientThemeSettings : defaultThemeSettings} />
            )
        }

        if (canceled) {
            return (
                <CanceledPage clientThemeSettings={clientThemeSettings ? clientThemeSettings : defaultThemeSettings} />
            )
        }

        if (expired) {
            return (
                <ExpiredPage clientThemeSettings={clientThemeSettings ? clientThemeSettings : defaultThemeSettings} />
            )
        }

        if (signerStep === "login") {
            return (
                <Login
                    setSignerStep={setSignerStep}
                    clientThemeSettings={clientThemeSettings}
                    signData={signData}
                    setSignData={setSignData}
                    onSendOTPCode={onSendOTPCode}
                />
            )
        } 
        else if (signerStep === "terms_of_use"){
            return (
                <TermsOfUse
                    setSignerStep={setSignerStep}
                    clientThemeSettings={clientThemeSettings}
                    signData={signData}
                    setSignData={setSignData}
                />
            )
        }
        else if (signerStep === "term_of_authorization") {
            return (
                <TermOfAuthorization
                    setSignerStep={setSignerStep}
                    clientThemeSettings={clientThemeSettings}
                    signData={signData}
                    setSignData={setSignData}
                />
            )
        }
        else if (signerStep === "resume_operation") {
            return (
                <ResumeOperation
                    setSignerStep={setSignerStep}
                    clientThemeSettings={clientThemeSettings}
                    signData={signData}
                    setSignData={setSignData}
                />
            )
        }
        else if (signerStep === "resume_insurance") {
            return (
                <ResumeInsurance
                    setSignerStep={setSignerStep}
                    clientThemeSettings={clientThemeSettings}
                    signData={signData}
                    setSignData={setSignData}
                />
            )
        }
        else if (signerStep === "fraud_warning") {
            return (
                <FraudWarning
                    setSignerStep={setSignerStep}
                    clientThemeSettings={clientThemeSettings}
                    signData={signData}
                    setSignData={setSignData}
                />
            )
        }
        else if (signerStep === "document_display") {
            return (
                <DocumentsInbox
                    setSignerStep={setSignerStep}
                    clientThemeSettings={clientThemeSettings}
                    signData={signData}
                    setSignData={setSignData}
                />
            )
        }
         else if (signerStep === "face_recognition") {
            return (
                <FaceRecognition
                    setSignerStep={setSignerStep}
                    clientThemeSettings={clientThemeSettings}
                    signData={signData}
                    setSignData={setSignData}
                />
            )
        } 
        else if (signerStep === "personal_document") {
            return (
                <PersonalDocument
                    setSignerStep={setSignerStep}
                    signData={signData}
                    clientThemeSettings={clientThemeSettings}
                    setSignData={setSignData}
                />
            )
        }
        else if (signerStep === "additional_document") {
            return (
                <AdditionalDocument
                    setSignerStep={setSignerStep}
                    signData={signData}
                    clientThemeSettings={clientThemeSettings}
                    setSignData={setSignData}
                />
            )
        }
        else if (signerStep === "device_scan") {
            return (
                <DeviceScan
                    setSignerStep={setSignerStep}
                    clientThemeSettings={clientThemeSettings}
                    signData={signData}
                    setSignData={setSignData}
                    isBatch={false}
                />
            )
        }
        else if (signerStep === "authentication_code") {
            return (
                <OTPPage
                    setSignerStep={setSignerStep}
                    clientThemeSettings={clientThemeSettings}
                    setSignData={setSignData}
                    signData={signData}
                    onSendOTPCode={onSendOTPCode}
                />
            )
        } else if (signerStep === "signed") {
            return (
                <SignedPage
                    clientThemeSettings={clientThemeSettings}
                    alreadySigned={alreadySigned}
                    signerHash={signerHash}
                    signData={signData}
                />
            )
        } else {
            return (
                <NotFoundPage clientThemeSettings={clientThemeSettings ? clientThemeSettings : defaultThemeSettings} />
            )
        }
    }

    return (
        <ErrorBoundaryComponent clientThemeSettings={clientThemeSettings}>
            {getScenes()}
        </ErrorBoundaryComponent>
    )
}